import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

export type CarouselProps = {
  /** Override children.length */
  amountToShow?: number;
  children: JSX.Element[];
  /** The gap between the elements */
  gap: number;
  horizontalPadding?: number;
  /** The min width of an element before it starts being scrollable */
  minWidth: number;
};

/**
 * Creates a carousel which only enables when the width is tight enough
 */
const Carousel: React.FC<CarouselProps> = props => {
  const { children, gap, minWidth, amountToShow = children.length } = props;

  const swiperSlideWidth = useMemo(() => {
    return `calc(100% / ${amountToShow} - ${gap}px + ${gap}px / ${amountToShow})`;
  }, [amountToShow, gap]);

  return (
    <Swiper
      spaceBetween={gap}
      slidesPerView="auto"
      mousewheel={{ forceToAxis: true }}
      freeMode={true}
      style={{
        padding: props.horizontalPadding ? `0px ${props.horizontalPadding}px` : undefined,
      }}
    >
      {props.children.map((child, i) => {
        return (
          <SwiperSlide
            style={{
              minWidth: `${minWidth}px`,
              width: swiperSlideWidth,
            }}
            key={i}
          >
            {child}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
