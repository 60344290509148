import React, { useEffect, useId, useState } from 'react';
import { detect } from 'detect-browser';
import Link from 'next/link';

import api from 'api';
import type {
  Block as BlockProps,
  Blocks,
  Hero as HeroProps,
  Split as SplitProps,
} from 'types/Home';
import { cx } from 'utils';
import SanityUrlBuilder from 'utils/SanityUrlBuilder';
import classes from './Blocks.module.css';

export type BlocksProps = {
  blocks: Blocks;
};

export type WithSharedProps<T> = { half?: boolean } & T;

function Hero(block: WithSharedProps<HeroProps>) {
  const builder = new SanityUrlBuilder(api().sanity.client);
  const id = useId().replace(/:(.+):/, 'block-$1-video');
  const [browser, setBrowser] = useState<string | null>(null);
  const media = block.media[0];

  useEffect(() => {
    const element = document.getElementById(id) as HTMLVideoElement | null;

    if (element) {
      element.muted = true;
      element.defaultMuted = true;
    }
  }, [id]);

  useEffect(() => {
    setBrowser(detect()?.name ?? null);
  }, []);

  const imageClasses = cx(classes.image, block.half ? classes.half : classes.wide);
  const imageWidth = block.half ? 1080 : 1920;
  return (
    <figure className={classes.hero}>
      <div className={imageClasses}>
        <div className={classes.content}>
          <div
            className={imageClasses}
            style={{
              backgroundImage: `url("${builder
                .image(media.type === 'image' ? media.source : media.thumbnail)
                .fit('fill')
                .width(imageWidth)
                .quality(80)
                .url()}")`,
            }}
          />
          {media.type === 'video' ? (
            browser && ['safari', 'ios'].includes(browser) ? (
              // Safari work around :^)
              <img src={builder.asset(media.video).url()} className={classes.media} />
            ) : (
              <video
                muted
                autoPlay
                loop
                playsInline
                src={builder.asset(media.video).url()}
                className={classes.media}
              />
            )
          ) : null}
        </div>
      </div>

      <figcaption
        className={cx(classes.caption, {
          [classes.top]: block.alignment === 'top',
          [classes.middle]: block.alignment === 'middle',
          [classes.bottom]: block.alignment === 'bottom',
          [classes.white]: block.color === 'white',
          [classes.black]: block.color === 'black',
          [classes.whiteMobile]: block.color === 'white-mobile',
          [classes.blackMobile]: block.color === 'black-mobile',
          [classes.half]: block.half,
        })}
      >
        <h2>{block.heading}</h2>
        <p>{block.subheading}</p>
        <Link href={block.cta.href}>{block.cta.text}</Link>
      </figcaption>
    </figure>
  );
}

function Split(props: SplitProps) {
  return (
    <section className={classes.split}>
      <Block {...props.left[0]} half />
      <Block {...props.right[0]} half />
    </section>
  );
}

function Block(block: WithSharedProps<BlockProps>) {
  switch (block._type) {
    case 'hero':
      return <Hero {...block} />;
    case 'split':
      return <Split {...block} />;
    default:
      throw new Error(`Unknown block type: ${JSON.stringify(block)}`);
  }
}

function Blocks({ blocks }: BlocksProps) {
  return blocks.map((block, i) => <Block key={i} {...block} />);
}

export default Blocks;
