import React from 'react';

import useIntersection from 'hooks/useIntersection';
import { cx } from 'utils';
import styles from './Image.module.css';

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  alt: string;
  aspectRatio?: 'square' | 'wide' | 'letterbox' | 'letterbox-portrait' | 'none';
  className?: string;
  src: string;
}

// value in pixels for when to load offscreen images
const LOAD_OFFSET = 100;

const Image = ({ alt, aspectRatio = 'square', className, src, ...rest }: ImageProps) => {
  const [isInView, setIsInView] = React.useState(false);
  const ref = React.useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = React.useState(false);

  useIntersection(
    ref,
    () => {
      setIsInView(true);
    },
    { rootMargin: `${LOAD_OFFSET}px` },
  );

  return (
    <div
      ref={ref}
      className={cx(
        styles.root,
        {
          [styles.absolute]: aspectRatio !== 'none',
          [styles['aspect-ratio-square']]: aspectRatio === 'square',
          [styles['aspect-ratio-wide']]: aspectRatio === 'wide',
          [styles['aspect-ratio-letterbox']]: aspectRatio === 'letterbox',
          [styles['aspect-ratio-letterbox-portrait']]: aspectRatio === 'letterbox-portrait',
        },
        className,
      )}
    >
      {isInView && (
        <img
          className={cx(styles.image, {
            [styles.absolute]: aspectRatio !== 'none',
            [styles.loaded]: loaded,
          })}
          onLoad={() => setLoaded(true)}
          alt={alt} // technically spread, but linter complains otherwise, so let's use TS to enforce usage.
          src={src}
          {...rest}
        />
      )}
    </div>
  );
};

export default Image;
