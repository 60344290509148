import React from 'react';
import { SanityImageSource } from '@sanity/asset-utils';
import useMedia from 'use-media';

import api from 'api';
import Block from 'components/ui/Block';
import Carousel from 'components/ui/Carousel';
import Container from 'components/ui/Container';
import CountDownTimer from 'components/ui/CountDownTimer';
import Image from 'components/ui/Image';
import Typography from 'components/ui/Typography';
import { pluralize, SanityUrlBuilder } from 'utils';
import classes from './NextReleaseSneakPeak.module.css';

export type NextReleaseSneakPeakProps = {
  amountOfItems: number;
  date: Date;
  images: SanityImageSource[];
};

function NextReleaseSneakPeak(props: NextReleaseSneakPeakProps) {
  const productText = pluralize('{count} produkt', '{count} produkter');
  const phone = useMedia({ maxWidth: 599 });
  const builder = new SanityUrlBuilder(api().sanity.client);

  return (
    <>
      <Block background="secondary" padding="regular">
        <Container>
          <div className={classes.topHeader}>
            <Typography component="h3" variant="h1">
              Nästa släpp av {productText(props.amountOfItems)} om:{' '}
              <Typography variant="h1-light">
                <CountDownTimer date={props.date}></CountDownTimer>
              </Typography>
            </Typography>
          </div>
          <Typography component="p" className={classes.tagline}>
            Sneak peek på nästa släpp
          </Typography>
        </Container>

        <Container padding="none">
          <div className={classes.carousel}>
            <Carousel horizontalPadding={phone ? 0 : 24} gap={phone ? 2 : 24} minWidth={212}>
              {props.images.map((v, i) => {
                return (
                  <Image
                    src={
                      /https:\/\//.test(String(v))
                        ? String(v)
                        : builder.image(v).width(500).height(700).url()
                    }
                    alt="alt"
                    key={i}
                    aspectRatio="letterbox-portrait"
                  />
                );
              })}
            </Carousel>
          </div>
        </Container>
      </Block>
    </>
  );
}

export default NextReleaseSneakPeak;
