import React from 'react';

import Block from 'components/ui/Block';
import Button from 'components/ui/Button';
import Container from 'components/ui/Container';
import Typography from 'components/ui/Typography';
import classes from './LoadMoreProducts.module.css';

export type LoadMoreProductsProps = {
  loading?: boolean;
  onClick?: React.ComponentProps<'button'>['onClick'];
};

function LoadMoreProducts(props: LoadMoreProductsProps) {
  return (
    <Container>
      <Block style={{ paddingTop: `${80 - 48}px` }} padding="big">
        <div className={classes.cont}>
          <Button onClick={props.onClick} className={classes.button} color="tertiary">
            {props.loading ? 'Laddar...' : 'Visa flera produkter'}
          </Button>
          <div className={classes.textCont}>
            <Typography component="p" align="center" className={classes.desc}>
              ARKIVET släpper nya produkter dagligen och varje släpp innehåller unika produkter som
              bara finns i ett exemplar. Visa fler produkter för att utforska sådant som fortfarande
              finns till försäljning från tidigare släpp.
            </Typography>
          </div>
        </div>
      </Block>
    </Container>
  );
}

export default LoadMoreProducts;
