import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useMedia from 'use-media';

import api from 'api';
import { useClickOutside, useHeight } from 'hooks';
import { AvailabilityMap } from 'types/AvailabilityMap';
import { Look } from 'types/Look';
import { cx, SanityUrlBuilder } from 'utils';
import Image from '../Image';
import Typography from '../Typography';
import classes from './LookItem.module.css';
import getRects, { Pair } from './RectPlacement';

export type LookItemProps = {
  availabilityMap: AvailabilityMap;
  look: Look;
};

const LookItem: React.FC<LookItemProps> = props => {
  const builder = new SanityUrlBuilder(api().sanity.client);
  const ref = useRef<HTMLDivElement>(null);
  const id = useMemo(() => props.look.products.map(v => v.product.name).join(''), [props.look]);
  const [contRef, height] = useHeight();

  const updateAllBoxPositions = useCallback(() => {
    // This is called after render
    const element = ref.current;

    if (!element) {
      return;
    }

    const contBounds = element.getBoundingClientRect();
    const bounds = {
      height: contBounds.height,
      width: contBounds.width,
      x: 0,
      y: 0,
    };
    const pairs: Pair[] = [];

    const boxes = Array.from(element.querySelectorAll(`div.${classes.box}`)) as HTMLDivElement[];

    for (const box of boxes) {
      const x = parseFloat(box.getAttribute('data-x') as string);
      const y = parseFloat(box.getAttribute('data-y') as string);
      const id = box.getAttribute('data-id') as string;
      const bounds = box.getBoundingClientRect();

      pairs.push({
        id,
        point: {
          height: 24,
          width: 24,
          x: contBounds.width * x - 12,
          y: contBounds.height * y - 12,
        },
        rect: { height: bounds.height, width: bounds.width, x: 0, y: 0 },
      });
    }

    getRects(bounds, pairs, id).forEach(v => {
      const box = element.querySelector<HTMLElement>(`[data-id="${v.id}"]`);

      if (!box) {
        throw new Error("Id doesn't exist");
      }

      box.style.top = `${v.rect.y}px`;
      box.style.left = `${v.rect.x}px`;
    });
  }, [id]);

  // Run logic to place all small boxes after render
  useEffect(() => {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          updateAllBoxPositions();
        }, 0);
      });
    }, 0);
  }, [props.look, height, updateAllBoxPositions]);

  const showHideAllPoints = useMedia({ maxWidth: 900 });
  const [allShown, setAllShown] = useState(false);
  const [singleBoxes, setBoxes] = useState<Record<string, boolean>>({});

  const boxes = useMemo((): Record<string, boolean> => {
    if (!showHideAllPoints) {
      return singleBoxes;
    }
    return allShown
      ? (Object.fromEntries(props.look.products.map((_, i) => [i, true])) as Record<
          string,
          boolean
        >)
      : {};
  }, [singleBoxes, props.look.products, showHideAllPoints, allShown]);

  useClickOutside(contRef, () => {
    setAllShown(false);
  });

  const router = useRouter();

  return (
    <div ref={contRef} onMouseLeave={() => setBoxes({})} className={classes.inspiration}>
      <div style={{ position: 'relative' }} onClick={() => setAllShown(!allShown)} ref={ref}>
        {props.look.products.map(({ product, x, y }, i) => {
          const sold = props.availabilityMap[product.groupReference]?.isAvailable === false;

          return (
            <div
              key={`${i}-message-box`}
              data-id={`${i}`}
              data-x={x}
              data-y={y}
              data-product-slug={product.slug}
              onClick={e => {
                if (!boxes[`${i}`]) {
                  return;
                }
                e.stopPropagation();
                router.push(`/produkt/${product.slug}?back=true`);
              }}
              className={cx(classes.box, boxes[`${i}`] && classes.shown, sold && classes.sold)}
            >
              <Typography component="p" variant="b">
                {product.name}
              </Typography>
              <Typography component="p" variant="b">
                {sold ? 'Såld' : `${product.price} SEK`}
              </Typography>
            </div>
          );
        })}
        {props.look.products.map((product, i) => {
          return (
            <div
              key={i}
              onMouseOver={() => setBoxes({ [`${i}`]: true })}
              onClick={() => router.push(`/produkt/${product.product.slug}?back=true`)}
              style={{
                left: `${product.x * 100}%`,
                top: `${product.y * 100}%`,
              }}
              className={classes.point}
            ></div>
          );
        })}
        <Image
          alt="alt text"
          aspectRatio="letterbox-portrait"
          src={builder
            .image(props.look.image)
            .fit('fill')
            .width(500 * 2)
            .height(700 * 2)
            .quality(80)
            .focalPoint(0.5, 0.5)
            .url()}
        />
      </div>
      <Typography className={classes.title} component="h3">
        {props.look.name}
      </Typography>
    </div>
  );
};

export default LookItem;
