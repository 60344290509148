import React from 'react';
import cx from 'classnames';

import styles from './Grid.module.css';

export interface GridProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  columns?: 2 | 3 | 4;
  component?: React.ElementType;
  gap?: 'small' | 'regular' | 'big';
}

/**
 * A simple grid-layout
 */

const Grid: React.FC<GridProps> = ({
  children,
  className,
  columns = 2,
  gap = 'regular',
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.root,
        {
          [styles[`gap-${gap}`]]: gap,
          [styles[`columns-${columns}`]]: columns,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Grid;
