import { CACHE_TIMES, DISABLE_ECOMMERCE } from 'consts';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';

import api from 'api';
import Blocks from 'components/sections/Blocks';
import Stores from 'components/sections/Stores';
import EcommerceHome from 'components/views/EcommerceHome';
import { AppLayoutProps } from 'types/AppLayoutProps';
import { AvailabilityMap } from 'types/AvailabilityMap';
import { Collection } from 'types/Collection';
import { Nullish } from 'types/helpers';
import { Home } from 'types/Home';
import { Look } from 'types/Look';
import { FooterMenuItem, NavbarMenuItem } from 'types/Menu';
import { ProductSmall } from 'types/Product';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { Store } from 'types/Store';
import { chunkAvailability, getBananasCommerce, getGroupReferences, setCacheHeaders } from 'utils';

export const getServerSideProps: GetServerSideProps<Props> = async ctx => {
  const sanity = api().sanity;

  const sanityQueries: [
    Promise<NavbarMenuItem[]>,
    Promise<FooterMenuItem[]>,
    Promise<Home>,
    Promise<Store[]>,
    Nullish<Promise<Collection[]>>,
    Nullish<Promise<(ProductSmall | Look)[]>>,
    Nullish<Promise<ScheduledRelease[]>>,
  ] = [
    sanity.navbarMenu(),
    sanity.footerMenu(),
    sanity.home(),
    sanity.stores(),
    undefined,
    undefined,
    undefined,
  ];
  const ecommerce: Partial<Props['ecommerce']> = {};

  if (!DISABLE_ECOMMERCE) {
    // const hideSold = "hideSold" in ctx.query ?? false;
    const collection = ctx.query.collection as string | undefined;
    ecommerce.page = (() => {
      const rawPage = parseInt(`${ctx.query.page}`);

      // To really make sure that this is a valid integer parseInt could return NaN
      if (!Number.isInteger(rawPage)) {
        return 1;
      }

      return Math.max(1, rawPage);
    })();

    sanityQueries[4] = sanity.collections();
    sanityQueries[5] = sanity.feed({
      collection,
      limit: ecommerce.page,
      offset: 0,
    });
    sanityQueries[6] = sanity.nextScheduledReleases();
  }

  // Fetch data for this request
  const [navbarMenu, footerMenu, home, stores, collections, feed, sneakPeeks] =
    await Promise.all(sanityQueries);

  if (!DISABLE_ECOMMERCE) {
    if (collections == null) throw new Error('Collections should be defined');
    if (feed == null) throw new Error('Feed should be defined');
    if (sneakPeeks == null) throw new Error('Sneak peeks should be defined');

    ecommerce.feed = feed;
    ecommerce.collections = collections;

    const bcom = getBananasCommerce();
    ecommerce.availabilityMap = Object.fromEntries(
      await chunkAvailability(bcom, getGroupReferences(feed)).then(v => {
        if (v._type !== 'success') {
          return [];
        }
        return v.groups.map(v => [v.reference, v] as const);
      }),
    );
    ecommerce.sneakPeek = sneakPeeks.at(0) ?? null;
    ecommerce.nextRelease = ecommerce.sneakPeek?.releaseAt ?? null;
  }

  const props: Props = {
    ecommerce: DISABLE_ECOMMERCE ? null : (ecommerce as Props['ecommerce']),
    footerMenu,
    home,
    navbarMenu,
    showWelcomeBanner: true,
    stores,
  };

  setCacheHeaders(ctx.res, CACHE_TIMES.HOME);

  return {
    props,
  };
};

type Props = {
  ecommerce: Nullish<{
    availabilityMap: AvailabilityMap;
    collections: Collection[];
    feed: (ProductSmall | Look)[];
    /** This is actually a Date string */
    nextRelease?: string | null;
    page: number;
    sneakPeek: ScheduledRelease | null;
  }>;
  home: Home;
  stores: Store[];
} & AppLayoutProps;

const Page: NextPage<Props> = props => {
  return (
    <>
      <Head>
        <title>Startsida - Arkivet Second Hand</title>
        <meta name="description" content="Arkivet Second Hand" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Startsida - Arkivet Second Hand" />
        <meta
          property="og:description"
          content="Med en hög modegrad och stort fokus på upplevelsen och känslan i butiken vill Arkivet göra det enkelt för kunderna att konsumera mode på ett hållbart sätt. Konceptet är enkelt; lämna in det som du inte längre använder men själv skulle kunna tänka dig att köpa, det vill säga moderna kläder i bra skick."
        />
        <meta property="og:url" content="https://arkivet.com/" />
      </Head>
      <Blocks blocks={props.home.blocks} />
      <Stores stores={props.stores} />
      {props.ecommerce && (
        <EcommerceHome
          page={props.ecommerce.page}
          collections={props.ecommerce.collections}
          availabilityMap={props.ecommerce.availabilityMap}
          sneakPeek={props.ecommerce.sneakPeek}
          feed={props.ecommerce.feed}
          nextRelease={props.ecommerce.nextRelease}
        />
      )}
    </>
  );
};

export default Page;
