import React from 'react';

import { cx } from 'utils';
import classes from './Toggle.module.css';

export type ToggleProps = React.ComponentProps<'input'> & {
  label?: string;
  labelPlacement?: 'left' | 'right';
};

function Toggle(props: ToggleProps) {
  const { labelPlacement: _, ...rest } = props;
  const labelPlacement = props.labelPlacement ?? 'right';

  return (
    <label className={classes.label}>
      {labelPlacement === 'left' && props.label}
      <input
        {...rest}
        type="checkbox"
        onChange={e => {
          rest.onChange?.(e);
        }}
        className={cx(classes.input, props.label != null && classes.padding, rest.className)}
      />
      {labelPlacement === 'right' && props.label}
    </label>
  );
}

export default Toggle;
