import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useMedia from 'use-media';

import api from 'api';
import LoadMoreProducts from 'components/sections/LoadMoreProducts';
import NextReleaseSneakPeak from 'components/sections/NextReleaseSneakPeak';
import Block from 'components/ui/Block';
import CollectionCircle from 'components/ui/CollectionCircle';
import Container from 'components/ui/Container';
import Grid from 'components/ui/Grid';
import Toggle from 'components/ui/Inputs/Toggle';
import LookItem from 'components/ui/LookItem';
import ProductItem from 'components/ui/ProductItem';
import Typography from 'components/ui/Typography';
import { useCookieAcceptState } from 'contexts/CookieContext';
import useBananasCommerce from 'hooks/useBananasCommerce';
import gtag from 'lib/gtag';
import { AvailabilityMap } from 'types/AvailabilityMap';
import { Collection } from 'types/Collection';
import { Look } from 'types/Look';
import { ProductSmall } from 'types/Product';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { chunkAvailability, SanityUrlBuilder } from 'utils';
import { getGroupReferences, productToGtagItem } from 'utils';
import classes from './EcommerceHome.module.css';

export type EcommerceHomeProps = {
  availabilityMap: AvailabilityMap;
  collections: Collection[];
  feed: (ProductSmall | Look)[];
  nextRelease?: string | null;
  page: number;
  sneakPeek: ScheduledRelease | null;
};

/*
 * Landing page for Arkivet
 */
const EcommerceHome: React.FC<EcommerceHomeProps> = props => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const bcom = useBananasCommerce();
  const builder = new SanityUrlBuilder(api().sanity.client);

  // This will most likely reapear on the page, so i don wont remove it for now
  // const productText = pluralize('{count} produkt', '{count} produkter');

  const phone = useMedia({ maxWidth: 599 });

  const { next } = api();

  const [feed, setFeed] = useState<(ProductSmall | Look)[]>(props.feed);

  useEffect(() => {
    setFeed(props.feed);
  }, [props.feed]);

  // Here null undefined mean two very different things.
  // Null - There is no data
  // Undefined - The data hasn't loaded yet
  // const [previousReleases, setPreviousReleases] = useState<(ProductSmall | Look)[]>();
  // const [nextRelease, setNextRelease] = useState<ScheduledRelease | null>();
  // const [currentRelease, setCurrentRelease] = useState<ScheduledRelease | null>();

  const [isFetchingLoadMore, setIsFetchingLoadMore] = useState(false);

  const setPage = useCallback(
    (page: number) => {
      if (page === 1) {
        const { page: _, ...query } = router.query;
        router.push({ query }, undefined, { shallow: true });
      } else {
        router.push({ query: { ...router.query, page } }, undefined, { shallow: true });
      }
    },
    [router],
  );
  const page = (() => {
    const rawPage = parseInt(`${router.query.page}`);

    // To really make sure that this is a valid integer parseInt could return NaN
    if (!Number.isInteger(rawPage)) {
      return 1;
    }

    return Math.max(1, rawPage);
  })();

  const setHideSold = useCallback(
    (hideSold: boolean) => {
      if (!hideSold) {
        const { hideSold: _, ...query } = router.query;
        router.push({ query }, undefined, { shallow: true });
      } else {
        router.push({ query: { ...router.query, hideSold } }, undefined, { shallow: true });
      }
    },
    [router],
  );
  const hideSold = router.query.hideSold === 'true';

  const setCollection = useCallback(
    (collection: string | null) => {
      const { collection: _0, page: _1, ...query } = router.query;
      if (collection == null) {
        router.push({ query });
      } else {
        router.push({ query: { ...query, collection } });
      }
    },
    [router],
  );
  const activeCollection = (router.query.collection ?? null) as string | null;

  const getQueryKey = (items: (Look | ProductSmall)[]) => [
    'availability-map',
    getGroupReferences(items),
  ];
  const { data: availabilityMap } = useQuery<AvailabilityMap>(
    getQueryKey(feed),
    async () => {
      const newAvailabilityMap = Object.fromEntries(
        await chunkAvailability(bcom, getGroupReferences(feed)).then(v => {
          if (v._type !== 'success') {
            return [];
          }
          return v.groups.map(v => [v.reference, v] as const);
        }),
      );
      return newAvailabilityMap;
    },
    {
      initialData: props.availabilityMap,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  /**
   * Handles when you click on load more
   */
  const onClickLoadMore = async () => {
    setIsFetchingLoadMore(true);

    const nextPage = await next.feed({
      collection: activeCollection,
      limit: 1,
      offset: page,
    });

    const newFeed = feed.concat(nextPage);

    const newAvailabilityMap = Object.fromEntries(
      await chunkAvailability(bcom, getGroupReferences(newFeed)).then(v => {
        if (v._type !== 'success') {
          return [];
        }
        return v.groups.map(v => [v.reference, v as typeof v | undefined] as const);
      }),
    );

    queryClient.setQueryData([getQueryKey(newFeed)], {
      ...availabilityMap,
      ...newAvailabilityMap,
    });

    setFeed(newFeed);
    setIsFetchingLoadMore(false);
    setPage(page + 1);
  };

  // Analytics
  const cookieAccept = useCookieAcceptState();

  const gtagItemListId = `latest-release-${page + 1}${hideSold ? '-hide-sold-items' : ''}`;
  const gtagItemListName = `Senaste släppet${hideSold ? ' (dölj sålda)' : ''}, sida ${page + 1}`;

  const allItemsGtag = useMemo(
    () =>
      feed
        .filter((v): v is ProductSmall => 'slug' in v)
        .filter(v => (hideSold ? availabilityMap[v.groupReference]?.isAvailable ?? true : true)),
    [feed, hideSold, availabilityMap],
  );

  useEffect(() => {
    const allItems = allItemsGtag.map((v, i) => productToGtagItem(v, i));

    if (cookieAccept.analytics && allItems.length !== 0) {
      gtag('event', 'view_item_list', {
        hide_sold: hideSold,
        item_list_id: gtagItemListId,
        item_list_name: gtagItemListName,
        items: allItems,
      });
    }
  }, [cookieAccept.analytics, gtagItemListId, gtagItemListName, allItemsGtag, page, hideSold]);

  const devicePixelRatio = typeof window === 'undefined' ? 2 : window.devicePixelRatio;

  return (
    <>
      <Container className={classes.collectionContainer}>
        <ul className={classes.collectionList}>
          <CollectionCircle
            alt="Miniatyrbild för new in"
            src={builder
              .image('image-a7c90c6f43261b6a76215a284db6f11c3d2500b0-1213x1213-jpg')
              .size(80, 80)
              .dpr(devicePixelRatio)
              .url()}
            active={activeCollection == null}
            onClick={() => setCollection(null)}
          >
            New in
          </CollectionCircle>
          {props.collections.map(collection => (
            <li key={collection.name}>
              <CollectionCircle
                alt={`Miniatyrbild för samlingen ${collection.name.toLocaleLowerCase()}`}
                src={builder.image(collection.image).size(80, 80).dpr(devicePixelRatio).url()}
                active={collection.slug === activeCollection}
                onClick={() => setCollection(collection.slug)}
              >
                {collection.name}
              </CollectionCircle>
            </li>
          ))}
        </ul>
      </Container>
      <Container className={classes.hideSold}>
        <Toggle
          label="DÖLJ SÅLDA"
          checked={hideSold}
          onChange={event => setHideSold(event.currentTarget.checked)}
        />
      </Container>
      <Container padding={phone ? 'none' : 'regular'}>
        <Block padding="regular">
          {feed.length === 0 ? (
            <div className={classes.somethingIsWrongContainer}>
              <Typography component="p" align="center">
                {activeCollection == null
                  ? 'Det finns inga aktiva släpp för tillfället.'
                  : 'Just nu finns det inga aktiva produkter i den här kategorin.'}
              </Typography>
            </div>
          ) : (
            <Grid gap="small" className={classes.grid} columns={3}>
              {feed.map((v, i) => {
                if ('products' in v) {
                  if (
                    hideSold &&
                    v.products.every(
                      ({ product }) =>
                        availabilityMap[product.groupReference]?.isAvailable !== false,
                    )
                  ) {
                    return;
                  }

                  return <LookItem availabilityMap={availabilityMap} key={i} look={v} />;
                }

                const availability = availabilityMap[v.groupReference];

                if (hideSold && !availability?.isAvailable) {
                  return;
                }

                return (
                  <ProductItem
                    availabilityEntry={availabilityMap[v.groupReference]}
                    onClick={() => {
                      if (cookieAccept.analytics) {
                        gtag('event', 'select_item', {
                          item_list_id: gtagItemListId,
                          item_list_name: gtagItemListName,
                          items: [productToGtagItem(v)],
                        });
                      }
                    }}
                    key={i}
                    product={v}
                  />
                );
              })}
            </Grid>
          )}
        </Block>
      </Container>
      {/* NOTE: We assume there is always more to load on the home page, no need
       * doing extra logic to determine if there is a next page. The feed should
       * be infinite anyways. */}
      <LoadMoreProducts loading={isFetchingLoadMore} onClick={onClickLoadMore} />
      {props.sneakPeek != null && (
        <NextReleaseSneakPeak
          amountOfItems={props.sneakPeek.items.length}
          date={new Date(props.sneakPeek.releaseAt)}
          images={props.sneakPeek.items
            .map(v => {
              if ('media' in v) {
                return v.media.type === 'image' ? v.media.source : v.media.thumbnail;
              }
              return v.image;
            })
            .slice(0, 20)}
        />
      )}
    </>
  );
};

export default EcommerceHome;
