import React, { useMemo } from 'react';
import { marked } from 'marked';

import { Store as StoreType } from 'types/Store';
import classes from './Stores.module.css';

export type StoresProps = {
  stores: StoreType[];
};

export type StoreProps = {
  store: StoreType;
};

function Store({ store }: StoreProps) {
  return (
    <li className={classes.item}>
      <article className={classes.store}>
        <details className={classes.details}>
          <summary className={classes.name}>{store.name}</summary>
          <article
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: marked.parse(store.details.content, { breaks: true }),
            }}
          />
        </details>
      </article>
    </li>
  );
}

function Stores({ stores }: StoresProps) {
  const storesByCities = useMemo(() => {
    const result: Record<string, StoreType[]> = {};
    for (const city of new Set(stores.map(store => store.city.split(/\s/)[0]))) {
      // eslint-disable-next-line
      result[city] ??= [];
      result[city].push(...stores.filter(store => store.city.startsWith(city)));
    }
    return Object.entries(result);
  }, [stores]);

  return (
    <section className={classes.section}>
      <h2>Våra butiker</h2>
      {storesByCities.map(([city, stores]) => (
        <section key={city} className={classes.city}>
          <h3 className={classes.cityHeading}>{city}</h3>
          <ul className={classes.stores}>
            {stores.map((store, i) => (
              <Store key={i} store={store} />
            ))}
          </ul>
        </section>
      ))}
    </section>
  );
}

export default Stores;
