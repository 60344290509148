import gen, { RandomSeed } from 'random-seed';
/*
 * This algorithm is for placing all boxes in the look component
 * We only know the coordinates of the points and need to fill in the boxes
 */

export type Rect = { height: number; width: number; x: number; y: number };

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Rect {
  /**
   * Checks if a intersects with b
   */
  export function intersect(a: Rect, b: Rect): boolean {
    if (a.x > b.x + b.width || b.x > a.x + a.width) {
      return false;
    }

    if (a.y > b.y + b.height || b.y > a.y + a.height) {
      return false;
    }

    return true;
  }

  /**
   * Adds margin to a rect
   */
  export function margin(a: Rect, margin: number) {
    return {
      height: a.height + 2 * margin,
      width: a.width + 2 * margin,
      x: a.x - margin,
      y: a.y - margin,
    };
  }

  /**
   * Checks if b is inside a
   */
  export function inside(a: Rect, b: Rect): boolean {
    return (
      a.x < b.x && a.x + a.width > b.x + b.width && a.y < b.y && a.y + a.height > b.y + b.height
    );
  }
}

function seedShuffle<T>(array: T[], rand: RandomSeed): T[] {
  const shuffledArray: T[] = [];
  const pool = array.map((_, i) => i);

  while (pool.length) {
    const index = rand(pool.length);
    shuffledArray.push(array[pool[index]]);
    pool.splice(index, 1);
  }

  return shuffledArray;
}

const BOUNDS_MARGIN = 8;
const SELF_POINT_MARGIN = 8;
const OTHER_POINT_MARGIN = 16;
const OTHER_RECT_MARGIN = 16;

/**
 * Returns the possible positions the rect can be without considering overlap
 * @param outerRect - The rect where everything must be contained within
 * @param rect - The rect to get possible positions
 * @param point - The point where the rect needs to be anchored to
 */
function getPossiblePositions(outerRect: Rect, rect: Rect, point: Rect, rand: RandomSeed) {
  const pointX = point.x + point.width / 2;
  const pointY = point.y + point.height / 2;

  // Hardcoded map of places the square can be
  // Yeah it's ugly :)
  // It works tho :O

  type Point = { x: number; y: number };

  const points: Point[] = (
    [
      // Right side of rectangle
      [
        {
          x: pointX + point.width / 2 + SELF_POINT_MARGIN,
          y: pointY - rect.height / 2,
        },
        // Left side of rectangle
        {
          x: pointX - point.width / 2 - SELF_POINT_MARGIN - rect.width,
          y: pointY - rect.height / 2,
        },
      ],
      // Top side, to the right
      [
        {
          x: Math.min(
            pointX - point.width / 2,
            outerRect.x + outerRect.width - rect.width - BOUNDS_MARGIN,
          ),
          y: pointY - point.height / 2 - SELF_POINT_MARGIN - rect.height,
        },
        // Top side, to the left
        {
          x: Math.max(pointX + point.width / 2 - rect.width, outerRect.x + BOUNDS_MARGIN),
          y: pointY - point.height / 2 - SELF_POINT_MARGIN - rect.height,
        },
        // Bottom side, to the right
        {
          x: Math.min(
            pointX - point.width / 2,
            outerRect.x + outerRect.width - rect.width - BOUNDS_MARGIN,
          ),
          y: pointY + point.height / 2 + SELF_POINT_MARGIN,
        },
        // Bottom side, to the left
        {
          x: Math.max(pointX + point.width / 2 - rect.width, outerRect.x + BOUNDS_MARGIN),
          y: pointY + point.height / 2 + SELF_POINT_MARGIN,
        },
      ],
      // Top, centred
      [
        {
          x: pointX - rect.width / 2,
          y: pointY - point.height / 2 - SELF_POINT_MARGIN - rect.height,
        },
        // bottom , centred
        {
          x: pointX - rect.width / 2,
          y: pointY + point.height / 2 + SELF_POINT_MARGIN,
        },
      ],
      // Right side, slightly higher
      [
        {
          x: pointX + point.width / 2 + SELF_POINT_MARGIN,
          y: pointY - point.height / 2,
        },
        // Left side, slightly higher
        {
          x: pointX - point.width / 2 - SELF_POINT_MARGIN - rect.width,
          y: pointY - point.height / 2,
        },
        // Right side, slightly lower
        {
          x: pointX + point.width / 2 + SELF_POINT_MARGIN,
          y: pointY + point.height / 2 - rect.height,
        },
        // Left side, slightly lower
        {
          x: pointX - point.width / 2 - SELF_POINT_MARGIN - rect.width,
          y: pointY + point.height / 2 - rect.height,
        },
      ],
    ] as Point[][]
  ).flatMap(v => seedShuffle(v, rand));

  const positions = points.map(point => ({
    ...point,
    height: rect.height,
    width: rect.width,
  }));

  return positions.filter(position => Rect.inside(outerRect, position));
}

export type Pair = { id: string; point: Rect; rect: Rect };

/**
 * Places rects recursively
 */
function getRects(
  bounds: Rect,
  pairs: Pair[],
  seed = `${Math.random()}`,
): { id: string; rect: Rect }[] {
  // This function might seem inefficient, but it runs under 10ms for 7 points in the browser, so it's actually pretty good

  const rand = gen.create(seed);

  // Remove reference
  const rr = <T>(v: T): T => JSON.parse(JSON.stringify(v)) as T;

  const recursive = (
    pair: Pair,
    pairs: Pair[],
    // If overlap shouldn't be considered in the final decision
    skipOverlap = false,
    placed: Rect[] = [],
    returnValue: { id: string; rect: Rect }[] = [],
  ): { id: string; rect: Rect }[] | false => {
    pairs = rr(pairs);
    placed = rr(placed);
    returnValue = rr(returnValue);

    const positions = getPossiblePositions(
      Rect.margin(bounds, -1 * BOUNDS_MARGIN),
      pair.rect,
      pair.point,
      rand,
    );

    for (const position of positions) {
      const intersectPlaced = placed.some(v => Rect.intersect(v, position));
      const intersectPoint = placed.some(v => Rect.intersect(v, pair.point));

      if (intersectPlaced || intersectPoint) {
        continue;
      }

      placed.push(
        Rect.margin(position, OTHER_RECT_MARGIN),
        Rect.margin(pair.point, OTHER_POINT_MARGIN),
      );

      returnValue.push({ id: pair.id, rect: position });

      if (pairs.length === 0) {
        return returnValue;
      }
      const [newPair, ...restPairs] = pairs;
      const worked = recursive(newPair, restPairs, skipOverlap, placed, returnValue);

      if (worked !== false) {
        return worked;
      }

      placed.pop();
      placed.pop();
      returnValue.pop();
    }

    if (skipOverlap) {
      const position = positions[0];

      placed.push(
        Rect.margin(position, OTHER_RECT_MARGIN),
        Rect.margin(pair.point, OTHER_POINT_MARGIN),
      );
      returnValue.push({ id: pair.id, rect: position });

      if (pairs.length === 0) {
        return returnValue;
      }
      const [newPair, ...restPairs] = pairs;
      return recursive(newPair, restPairs, true, placed, returnValue);
    }

    return false;
  };

  if (pairs.length === 0) {
    return [];
  }

  const [pair, ...restPairs] = pairs;

  return recursive(pair, restPairs) || recursive(pair, restPairs, true) || [];
}

export default getRects;
