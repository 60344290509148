import dayjs from 'dayjs';

/**
 * Returns the difference between two dates as a time string
 * @example
 * getTimeDifferenceString(dateOne, dateTwo); // 4:25:08
 * getTimeDifferenceString(dateOne, dateTwo); // 204:02:32
 * getTimeDifferenceString(dateOne, dateTwo); // 0:00:54
 */
export const getTimeDifferenceString = (a: Date, b: Date) => {
  const first = dayjs(a > b ? b : a);
  let next = dayjs(a > b ? a : b);

  const units = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
  };

  for (const unit of ['day', 'hour', 'minute', 'second'] as const) {
    const diff = next.diff(first, unit);
    next = next.subtract(diff, unit);

    units[unit] = diff;
  }

  if (units.day === 0) {
    return `${units.hour}:${paddNumber(units.minute)}:${paddNumber(units.second)}`;
  }

  return `${units.day}:${paddNumber(units.hour)}:${paddNumber(units.minute)}:${paddNumber(
    units.second,
  )}`;
};

function paddNumber(value: string | number): string {
  const raw = `${value}`;

  if (!(raw.length - 1)) {
    return `0${raw}`;
  }
  return raw;
}
