import React, { useEffect, useState } from 'react';

import { getTimeDifferenceString } from 'utils/getTimeDifferenceString';

export type CountDownTimerProps = {
  date: Date;
};

const CountDownTimer: React.FC<CountDownTimerProps> = props => {
  const { date } = props;

  const getDiffToday = (date: Date): string => {
    const today = new Date();

    if (date < today) {
      return `0:00:00`;
    }

    return getTimeDifferenceString(today, date);
  };

  // Set default to 0:00:00 to prevent hydration errors from next...
  // In the future it might be best to place this in a context somehow
  const [timer, setTimer] = useState('0:00:00');

  useEffect(() => {
    const loopId = setInterval(() => {
      setTimer(getDiffToday(date));

      // Loop faster than one second to ensure no skips of two seconds happen
    }, 200);

    return () => clearInterval(loopId);
  }, [date]);

  return <>{timer}</>;
};

export default CountDownTimer;
