import React from 'react';

import Clickable, { ClickableProps } from 'components/ui/Clickable';
import Image, { ImageProps } from 'components/ui/Image';
import { cx } from 'utils';
import Typography from '../Typography';
import classes from './CollectionCircle.module.css';

export type CollectionCircleProps = {
  active?: boolean;
  children: React.ReactNode;
} & Pick<ImageProps, 'src' | 'alt'> &
  React.ComponentPropsWithoutRef<'button'> &
  ClickableProps;

const CollectionCircle: React.FC<CollectionCircleProps> = React.forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  CollectionCircleProps
>(({ active, alt, children, className, src, ...rest }, ref) => {
  return (
    <Clickable ref={ref} className={cx(classes.root, className)} {...rest}>
      <Image alt={alt} src={src} className={classes.image} aspectRatio="square" />
      <Typography className={cx(classes.label, active && classes.active)}>{children}</Typography>
    </Clickable>
  );
});

CollectionCircle.displayName = 'CollectionCircle';

export default CollectionCircle;
